import { ZoomMtg } from '@zoomus/websdk';
import { useEffect } from 'react';
import crypto from 'crypto';

const { REACT_APP_ZOOM_API_KEY: apiKey, REACT_APP_ZOOM_API_SECRET: apiSecret } =
  process.env;

const paramsString = window.location.href.split('?')[1] || '';
const params = new URLSearchParams(paramsString);
const meetingIsOver = params.get('meetingIsOver') || false;
const meetingNumber = params.get('meetingNumber');
const leaveUrl = window.location.href + '&meetingIsOver=true';
const userName = params.get('userName') || 'User';
const userEmail = params.get('userEmail') || ''; //optional
const passWord = params.get('passWord') || ''; //optional
let signature = '';

const Zoom = () => {
  useEffect(() => {
    try {
      if (meetingIsOver) {
        window.location.href = 'dantamitra://';
      } else if (apiKey && apiSecret && meetingNumber && leaveUrl) {
        generateSignature(apiKey, apiSecret, meetingNumber, 0).then((res) => {
          signature = res;
          document.getElementById('zmmtg-root').style.display = 'block';
          ZoomMtg.setZoomJSLib('/zoom/lib', '/av');
          ZoomMtg.preLoadWasm();
          ZoomMtg.prepareJssdk();
          initiateMeeting();
        });
      } else {
        throw new Error('no required fields');
      }
    } catch (err) {
      console.log('🚀 ~ file: Zoom.js ~ line 35 ~ useEffect ~ err', err);
    }
  }, []);

  const generateSignature = (apiKey, apiSecret, meetingNumber, role) => {
    return new Promise((res) => {
      const timestamp = new Date().getTime() - 30000;
      const msg = Buffer.from(
        apiKey + meetingNumber + timestamp + role
      ).toString('base64');
      const hash = crypto
        .createHmac('sha256', apiSecret)
        .update(msg)
        .digest('base64');
      const signature = Buffer.from(
        `${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`
      ).toString('base64');

      res(signature);
    });
  };

  const initiateMeeting = () => {
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(
          '🚀 ~ file: Zoom.js ~ line 63 ~ initiateMeeting init~ success',
          success
        );

        ZoomMtg.join({
          signature,
          meetingNumber,
          userName,
          apiKey,
          userEmail,
          passWord,
          success: (success) => {
            console.log(
              '🚀 ~ file: Zoom.js ~ line 73 ~ initiateMeeting join~ success',
              success
            );
          },
          error: (error) => {
            console.log(
              '🚀 ~ file: Zoom.js ~ line 76 ~ initiateMeeting join~ error',
              error
            );
          },
        });
      },
      error: (error) => {
        console.log(
          '🚀 ~ file: Zoom.js ~ line 81 ~ initiateMeeting init~ error',
          error
        );
      },
    });
  };

  return <div className="App">Zoom</div>;
};

export default Zoom;
